import React from "react"
import styled from "@emotion/styled"
import { useSpring } from "react-spring"
import { useSiteMetadata } from "helpers/hooks"
import { mqHandheld, mqTablet, mqTabletHandheld } from "components/index.css"
import { H2, P, Section } from "components/typography"
import SEO from "../components/seo"

const TermsAndConditions = () => {
  const { email } = useSiteMetadata()
  const props = useSpring({
    from: { opacity: 0, transform: `translateX(-20%)` },
    to: { opacity: 1, transform: `translateX(0)` },
  })

  return (
    <>
      <SEO title="Política de privacidad Términos &#38; condiciones" />
      <Section style={props}>
        <Copy>
          <H2>Condiciones de uso</H2>
          <P>
            La utilizaci&oacute;n del sitio Web le otorga la condici&oacute;n de
            Usuario, e implica la aceptaci&oacute;n completa de todas las
            cl&aacute;usulas y condiciones de uso incluidas en las secciones de
            esta p&aacute;gina:
          </P>
          <Nav>
            <List>
              <li>
                <A href="#aviso-legal">Aviso Legal</A>
              </li>
              <li>
                <A href="#politica-de-privacidad">
                  Pol&iacute;tica de Privacidad
                </A>
              </li>
              <li>
                <A href="#politica-de-cookies">Pol&iacute;tica de Cookies</A>
              </li>
            </List>
          </Nav>
        </Copy>
      </Section>
      <Section id="aviso-legal" style={props}>
        <Copy>
          <H2>Aviso Legal</H2>
          <P>
            Si no estuviera conforme con todas y cada una de estas
            cl&aacute;usulas y condiciones abst&eacute;ngase de utilizar este
            sitio Web.
          </P>
          <P>
            El acceso a este sitio Web no supone, en modo alguno, el inicio de
            una relaci&oacute;n comercial con el Titular.
          </P>
          <P>
            A trav&eacute;s de este sitio Web, el Titular le facilita el acceso
            y la utilizaci&oacute;n de diversos contenidos que el Titular o sus
            colaboradores han publicado por medio de Internet. A tal efecto,
            usted est&aacute; obligado y comprometido a NO utilizar cualquiera
            de los contenidos del sitio Web con fines o efectos il&iacute;citos,
            prohibidos en este Aviso Legal o por la legislaci&oacute;n vigente,
            lesivos de los derechos e intereses de terceros, o que de cualquier
            forma puedan da&ntilde;ar, inutilizar, sobrecargar, deteriorar o
            impedir la normal utilizaci&oacute;n de los contenidos, los equipos
            inform&aacute;ticos o los documentos, archivos y toda clase de
            contenidos almacenados en cualquier equipo inform&aacute;tico
            propios o contratados por el Titular, de otros usuarios o de
            cualquier usuario de Internet.
          </P>
          <Subtitle>Identificación y Titularidad</Subtitle>
          <P>
            En cumplimiento del art&iacute;culo 10 de la Ley 34 / 2002, de 11 de
            julio, de Servicios de la Sociedad de la Informaci&oacute;n y
            Comercio Electr&oacute;nico, el Titular expone sus datos
            identificativos.
          </P>
          <List>
            <li>
              <P>Titular: KEEP COMPLIANCE SL</P>
            </li>
            <li>
              <P>
                Registro mercantil: Registro Mercantil de A Coru&ntilde;a T
                3713, F 155, S 8, H C 59394
              </P>
            </li>
            <li>
              <P>NIF: B70608922</P>
            </li>
            <li>
              <P>Domicilio: Calle Juan Fl&oacute;rez, 129 2&ordm;Derecha</P>
            </li>
            <li>
              <P>
                Correo electrónico: <A href={`mailto:${email}`}>{`${email}`}</A>
              </P>
            </li>
            <li>
              <P>
                Sitio Web:{" "}
                <A href="https://www.keepcomp.com">www.keepcomp.com</A>
              </P>
            </li>
          </List>
          <Subtitle>Medidas de seguridad</Subtitle>
          <P>
            Los datos personales que facilite al Titular pueden ser almacenados
            en bases de datos automatizadas o no, cuya titularidad corresponde
            en exclusiva a el Titular, que asume todas las medidas de índole
            técnica, organizativa y de seguridad que garantizan la
            confidencialidad, integridad y calidad de la información contenida
            en las mismas de acuerdo con lo establecido en la normativa vigente
            en protección de datos. No obstante, debe ser consciente de que las
            medidas de seguridad de los sistemas informáticos en Internet no son
            enteramente fiables y que, por tanto el Titular no puede garantizar
            la inexistencia de virus u otros elementos que puedan producir
            alteraciones en los sistemas informáticos (software y hardware) del
            Usuario o en sus documentos electrónicos y ficheros contenidos en
            los mismos aunque el Titular pone todos los medios necesarios y toma
            las medidas de seguridad oportunas para evitar la presencia de estos
            elementos dañinos.
          </P>
          <Subtitle>Datos personales</Subtitle>
          <P>
            Usted puede consultar toda la informaci&oacute;n relativa al
            tratamiento de datos personales que recoge el Titular en el apartado
            de la{" "}
            <A href="#politica-de-privacidad">Pol&iacute;tica de Privacidad.</A>
          </P>
          <Subtitle>Contenidos</Subtitle>
          <P>
            El Titular ha obtenido la informaci&oacute;n, el contenido
            multimedia y los materiales incluidos en el sitio Web de fuentes que
            considera fiables, pero, si bien ha tomado todas las medidas
            razonables para asegurar que la informaci&oacute;n contenida es
            correcta, el Titular no garantiza que sea exacta, completa o
            actualizada. El Titular declina expresamente cualquier
            responsabilidad por error u omisi&oacute;n en la informaci&oacute;n
            contenida en las p&aacute;ginas de este sitio Web.
          </P>
          <P>
            Queda prohibido transmitir o enviar a trav&eacute;s del sitio Web
            cualquier contenido ilegal o il&iacute;cito, virus
            inform&aacute;ticos, o mensajes que, en general, afecten o violen
            derechos de el Titular o de terceros.
          </P>
          <P>
            Los contenidos del Sitio Web tienen &uacute;nicamente una finalidad
            informativa y bajo ninguna circunstancia deben usarse ni
            considerarse como oferta de venta, solicitud de una oferta de compra
            ni recomendaci&oacute;n para realizar cualquier otra
            operaci&oacute;n, salvo que as&iacute; se indique expresamente.
          </P>
          <P>
            El Titular se reserva el derecho a modificar, suspender, cancelar o
            restringir el contenido del Sitio Web, los v&iacute;nculos o la
            informaci&oacute;n obtenida a trav&eacute;s del sitio Web, sin
            necesidad de previo aviso.
          </P>
          <P>
            El Titular no es responsable de los da&ntilde;os y perjuicios que
            pudieran derivarse de la utilizaci&oacute;n de la informaci&oacute;n
            del sitio Web o de la contenida en las redes sociales del Titular.
          </P>
          <Subtitle>Acerca de cookies</Subtitle>
          <P>
            En la secci&oacute;n{" "}
            <A href="#politica-de-cookies">Pol&iacute;tica de Cookies</A> puede
            consultar toda la informaci&oacute;n relativa a la pol&iacute;tica
            de recogida y tratamiento de las cookies. El Titular s&oacute;lo
            obtiene y conserva la siguiente informaci&oacute;n acerca de los
            visitantes del Sitio Web:
          </P>
          <List>
            <li>
              <P>
                El nombre de dominio del proveedor (PSI) y/o direcci&oacute;n IP
                que da acceso a la red.
              </P>
            </li>
            <li>
              <P>La fecha y hora de acceso al sitio Web.</P>
            </li>
            <li>
              <P>
                La direcci&oacute;n de Internet origen del enlace que dirige al
                sitio Web.
              </P>
            </li>
            <li>
              <P>
                El n&uacute;mero de visitantes diarios de cada secci&oacute;n.
              </P>
            </li>
            <li>
              <P>
                La informaci&oacute;n obtenida es totalmente an&oacute;nima, y
                en ning&uacute;n caso puede ser asociada a un Usuario concreto e
                identificado.
              </P>
            </li>
          </List>
          <Subtitle>Acerca de cookies</Subtitle>
          <P>
            El Titular puede proporcionarle acceso a sitios Web de terceros
            mediante enlaces con la finalidad de informar sobre la existencia de
            otras fuentes de informaci&oacute;n en Internet en las que
            podr&aacute; ampliar los datos ofrecidos en el sitio Web.
          </P>
          <P>
            Estos enlaces a otros sitios Web no suponen en ning&uacute;n caso
            una sugerencia o recomendaci&oacute;n para que usted visite las
            p&aacute;ginas web de destino, que est&aacute;n fuera del control
            del Titular, por lo que Titular no es responsable del contenido de
            los sitios web vinculados ni del resultado que obtenga al seguir los
            enlaces.
          </P>
          <P>
            Asimismo, el Titular no responde de los links o enlaces ubicados en
            los sitios web vinculados a los que le proporciona acceso.
          </P>
          <P>
            El establecimiento del enlace no implica en ning&uacute;n caso la
            existencia de relaciones entre Titular y el propietario del sitio en
            el que se establezca el enlace, ni la aceptaci&oacute;n o
            aprobaci&oacute;n por parte del Titular de sus contenidos o
            servicios.
          </P>
          <P>
            Si accede a un sitio Web externo desde un enlace que encuentre en el
            Sitio Web usted deber&aacute; leer la propia pol&iacute;tica de
            privacidad del otro sitio web que puede ser diferente de la de este
            sitio Web.
          </P>
          <Subtitle>Propiedad intelectual e industrial</Subtitle>
          <P>Todos los derechos est&aacute;n reservados.</P>
          <P>
            Todo acceso a este sitio Web est&aacute; sujeto a las siguientes
            condiciones: la reproducci&oacute;n, almacenaje permanente y la
            difusi&oacute;n de los contenidos o cualquier otro uso que tenga
            finalidad p&uacute;blica o comercial queda expresamente prohibida
            sin el consentimiento previo expreso y por escrito de Titular.
          </P>
          <Subtitle>Limitaci&oacute;n de responsabilidad</Subtitle>
          <P>
            La informaci&oacute;n y servicios incluidos o disponibles a
            trav&eacute;s de este sitio Web pueden incluir incorrecciones o
            errores tipogr&aacute;ficos. De forma peri&oacute;dica el Titular
            incorpora mejoras y/o cambios a la informaci&oacute;n contenida y/o
            los Servicios que puede introducir en cualquier momento.
          </P>
          <P>
            El Titular no declara ni garantiza que los servicios o contenidos
            sean interrumpidos o que est&eacute;n libres de errores, que los
            defectos sean corregidos, o que el servicio o el servidor que lo
            pone a disposici&oacute;n est&eacute;n libres de virus u otros
            componentes nocivos sin perjuicio de que el Titular realiza todos
            los esfuerzos en evitar este tipo de incidentes.
          </P>
          <P>
            Titular declina cualquier responsabilidad en caso de que existan
            interrupciones o un mal funcionamiento de los Servicios o contenidos
            ofrecidos en Internet, cualquiera que sea su causa. Asimismo, el
            Titular no se hace responsable por ca&iacute;das de la red,
            p&eacute;rdidas de negocio a consecuencia de dichas ca&iacute;das,
            suspensiones temporales de fluido el&eacute;ctrico o cualquier otro
            tipo de da&ntilde;o indirecto que te pueda ser causado por causas
            ajenas a el Titular.
          </P>
          <P>
            Antes de tomar decisiones y/o acciones con base a la
            informaci&oacute;n incluida en el sitio Web, el Titular le
            recomienda comprobar y contrastar la informaci&oacute;n recibida con
            otras fuentes.
          </P>
          <Subtitle>Derechos de exclusi&oacute;n</Subtitle>
          <P>
            Titular se reserva el derecho a denegar o retirar el acceso al sitio
            Web y los servicios ofrecidos sin necesidad de preaviso, a instancia
            propia o de un tercero, a aquellos usuarios que incumplan cualquiera
            de las condiciones de este Aviso Legal.
          </P>
          <Subtitle>Jurisdicci&oacute;n</Subtitle>
          <P>
            Este Aviso Legal se rige &iacute;ntegramente por la
            legislaci&oacute;n espa&ntilde;ola.
          </P>
          <P>
            Siempre que no haya una norma que obligue a otra cosa, para cuantas
            cuestiones se susciten sobre la interpretaci&oacute;n,
            aplicaci&oacute;n y cumplimiento de este Aviso Legal, as&iacute;
            como de las reclamaciones que puedan derivarse de su uso, las partes
            acuerdan someterse a los Jueces y Tribunales de la provincia de A
            Coru&ntilde;a, con renuncia expresa de cualquier otra
            jurisdicci&oacute;n que pudiera corresponderles.
          </P>
          <Subtitle>Contacto</Subtitle>
          <P>
            En caso de que usted tenga cualquier duda acerca de estas
            Condiciones legales o quiera realizar cualquier comentario sobre
            este sitio Web, puede enviar un mensaje de correo electr&oacute;nico
            a la direcci&oacute;n{" "}
            <A href="mailto:info@keepcomp.com">info@keepcomp.com</A>.
          </P>
          <P>
            Keep Comliance S.L te informa sobre su Pol&iacute;tica de Privacidad
            respecto del tratamiento y protecci&oacute;n de los datos de
            car&aacute;cter personal de los usuarios y clientes que puedan ser
            recabados por la navegaci&oacute;n o contrataci&oacute;n de
            servicios a trav&eacute;s del sitio web kepcomp.com.
          </P>
          <P>
            En este sentido, el Titular garantiza el cumplimiento de la
            normativa vigente en materia de protecci&oacute;n de datos
            personales, reflejada en la Ley Org&aacute;nica 3/2018, de 5 de
            diciembre, de Protecci&oacute;n de Datos Personales y de
            Garant&iacute;a de Derechos Digitales (LOPD GDD). Cumple
            tambi&eacute;n con el Reglamento (UE) 2016/679 del Parlamento
            Europeo y del Consejo de 27 de abril de 2016 relativo a la
            protecci&oacute;n de las personas f&iacute;sicas (RGPD).
          </P>
        </Copy>
      </Section>
      <Section id="politica-de-privacidad" style={props}>
        <Copy>
          <H2>Pol&iacute;tica de privacidad</H2>
          <Subtitle>Principios aplicados en el tratamiento de datos</Subtitle>
          <P>
            En el tratamiento de tus datos personales, el Titular
            aplicar&aacute; los siguientes principios que se ajustan a las
            exigencias del nuevo reglamento europeo de protecci&oacute;n de
            datos:
          </P>
          <List>
            <li>
              <P>
                Principio de licitud, lealtad y transparencia: El Titular
                siempre requerir&aacute; el consentimiento para el tratamiento
                de tus datos personales que puede ser para uno o varios fines
                espec&iacute;ficos sobre los que te informar&aacute; previamente
                con absoluta transparencia.
              </P>
            </li>
            <li>
              <P>
                Principio de minimizaci&oacute;n de datos: El Titular te
                solicitar&aacute; solo los datos estrictamente necesarios para
                el fin o los fines que los solicita.
              </P>
            </li>
            <li>
              <P>
                Principio de limitaci&oacute;n del plazo de conservaci&oacute;n:
                Los datos se mantendr&aacute;n durante el tiempo estrictamente
                necesario para el fin o los fines del tratamiento. El Titular te
                informar&aacute; del plazo de conservaci&oacute;n
                correspondiente seg&uacute;n la finalidad. En el caso de
                suscripciones, el Titular revisar&aacute; peri&oacute;dicamente
                las listas y eliminar&aacute; aquellos registros inactivos
                durante un tiempo considerable.
              </P>
            </li>
            <li>
              <P>
                Principio de integridad y confidencialidad: Tus datos
                ser&aacute;n tratados de tal manera que su seguridad,
                confidencialidad e integridad est&eacute; garantizada. Debes
                saber que el Titular toma las precauciones necesarias para
                evitar el acceso no autorizado o uso indebido de los datos de
                sus usuarios por parte de terceros.
              </P>
            </li>
          </List>
          <Subtitle>Obtenci&oacute;n de datos personales</Subtitle>
          <P>
            Para navegar por keepcomp.com no es necesario que facilites
            ning&uacute;n dato personal. Los casos en los que s&iacute;
            proporcionas tus datos personales son los siguientes:
          </P>
          <List>
            <li>
              <P>Al enviar un correo electr&oacute;nico.</P>
            </li>
            <li>
              <P>
                Al seguirnos en redes sociales (pol&iacute;tica de privacidad de
                terceros)
              </P>
            </li>
          </List>
          <Subtitle>Sus derechos</Subtitle>
          <P>
            El Titular te informa que sobre tus datos personales tienes derecho
            a:
          </P>
          <List>
            <li>
              <P>Solicitar el acceso a los datos almacenados.</P>
            </li>
            <li>
              <P>Solicitar una rectificaci&oacute;n o la cancelaci&oacute;n.</P>
            </li>
            <li>
              <P>Solicitar la limitación de su tratamiento.</P>
            </li>
            <li>
              <P>Oponerte al tratamiento.</P>
            </li>
            <li>
              <P>Solicitar la portabilidad de tus datos.</P>
            </li>
          </List>
          <P>
            El ejercicio de estos derechos es personal y por tanto debe ser
            ejercido directamente por el interesado, solicit&aacute;ndolo
            directamente al Titular, lo que significa que cualquier cliente,
            suscriptor o colaborador que haya facilitado sus datos en
            alg&uacute;n momento puede dirigirse al Titular y pedir
            informaci&oacute;n sobre los datos que tiene almacenados y
            c&oacute;mo los ha obtenido, solicitar la rectificaci&oacute;n de
            los mismos, solicitar la portabilidad de sus datos personales,
            oponerse al tratamiento, limitar su uso o solicitar la
            cancelaci&oacute;n de esos datos en los ficheros del Titular.
          </P>
          <P>
            Para ejercitar tus derechos de acceso, rectificaci&oacute;n,
            cancelaci&oacute;n, portabilidad y oposici&oacute;n tienes que
            enviar un correo electr&oacute;nico a{" "}
            <A href="mailto:info@keepcomp.com">info@keepcomp.com</A> junto con
            la prueba v&aacute;lida en derecho como una fotocopia del D.N.I. o
            equivalente.
          </P>
          <P>
            Tienes derecho a la tutela judicial efectiva y a presentar una
            reclamaci&oacute;n ante la autoridad de control, en este caso, la
            Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos, si consideras
            que el tratamiento de datos personales que te conciernen infringe el
            Reglamento.
          </P>
          <Subtitle>Sus derechos</Subtitle>
          <P>
            Este sitio web no utiliza tus datos personales, ni contiene
            formularios, comentarios, logins, ni otros procesos que requieran el
            almacenamiento de los mismos Existen algunas finalidades por las que
            el Titular trata tus datos de forma no identificativa:
          </P>
          <List>
            <li>
              <P>
                Para garantizar el cumplimiento de las condiciones recogidas en
                el Aviso Legal y en la ley aplicable. Esto puede incluir el
                desarrollo de herramientas y algoritmos que ayuden a este sitio
                Web a garantizar la confidencialidad de los datos personales que
                recoge.
              </P>
            </li>
            <li>
              <P>
                Para apoyar y mejorar los servicios que ofrece este sitio Web.
              </P>
            </li>
            <li>
              <P>
                Para analizar la navegación. El Titular recoge otros datos no
                identificativos que se obtienen mediante el uso de cookies que
                se descargan en tu ordenador cuando navegas por el sitio Web
                cuyas características y finalidad están detalladas en la{" "}
                <A href="#politica-de-cookies">Pol&iacute;tica de Cookies</A>.
              </P>
            </li>
            <li>
              <P>
                Para gestionar las redes sociales. el Titular tiene presencia en
                redes sociales. Si te haces seguidor en las redes sociales del
                Titular el tratamiento de los datos personales se regirá por
                este apartado, así como por aquellas condiciones de uso,
                políticas de privacidad y normativas de acceso que pertenezcan a
                la red social que proceda en cada caso y que has aceptado
                previamente. El Titular tratará tus datos personales con la
                finalidad de administrar correctamente su presencia en la red
                social, informarte de sus actividades, productos o servicios,
                así como para cualquier otra finalidad que las normativas de las
                redes sociales permitan. En ningún caso el Titular utilizará los
                perfiles de seguidores en redes sociales para enviar publicidad
                de manera individual.
              </P>
            </li>
          </List>
          <Subtitle>Seguridad de los datos personales</Subtitle>
          <P>
            Para proteger tus datos personales, el Titular toma todas las
            precauciones razonables y sigue las mejores pr&aacute;cticas de la
            industria para evitar su p&eacute;rdida, mal uso, acceso indebido,
            divulgaci&oacute;n, alteraci&oacute;n o destrucci&oacute;n de los
            mismos.
          </P>
          <P>
            El sitio Web est&aacute; alojado en{" "}
            <A href="https://www.webempresa.com/">webempresa.com</A>. La
            seguridad de tus datos est&aacute; garantizada, ya que toman todas
            las medidas de seguridad necesarias para ello. Puedes consultar su
            pol&iacute;tica de privacidad para tener m&aacute;s
            informaci&oacute;n.
          </P>
          <Subtitle>Contenido de otros sitios web</Subtitle>
          <P>
            Las p&aacute;ginas de este sitio Web pueden incluir contenido
            incrustado (por ejemplo, v&iacute;deos, im&aacute;genes,
            art&iacute;culos, etc.). El contenido incrustado de otras web se
            comporta exactamente de la misma manera que si hubieras visitado la
            otra web.
          </P>
          <P>
            Estos sitios Web pueden recopilar datos sobre ti, utilizar cookies,
            incrustar un c&oacute;digo de seguimiento adicional de terceros, y
            supervisar tu interacci&oacute;n usando este c&oacute;digo.
          </P>
          <Subtitle>Acerca de cookies</Subtitle>
          <P>
            Para que este sitio Web funcione correctamente necesita utilizar
            cookies, que es una informaci&oacute;n que se almacena en tu
            navegador web.
          </P>
          <P>
            En la secci&oacute;n Pol&iacute;tica de Cookies puedes consultar
            toda la informaci&oacute;n relativa a la pol&iacute;tica de
            recogida, la finalidad y el tratamiento de las cookies.
          </P>
          <Subtitle>Conservaci&oacute;n de datos personales</Subtitle>
          <P>
            Este sitio web no almacena datos personales de visitantes o
            terceros.
          </P>
          <Subtitle>Navegaci&oacute;n web</Subtitle>
          <P>
            Al navegar por keepcomp.com se pueden recoger datos no
            identificativos, que pueden incluir, la direcci&oacute;n IP,
            geolocalizaci&oacute;n, un registro de c&oacute;mo se utilizan los
            servicios y sitios, h&aacute;bitos de navegaci&oacute;n y otros
            datos que no pueden ser utilizados para identificarte.
          </P>
          <P>
            El sitio Web utiliza los siguientes servicios de an&aacute;lisis de
            terceros:
          </P>
          <List>
            <li>
              <P>Google Analytics</P>
            </li>
          </List>
          <P>
            El Titular utiliza la informaci&oacute;n obtenida para obtener datos
            estad&iacute;sticos, analizar tendencias, administrar el sitio,
            estudiar patrones de navegaci&oacute;n y para recopilar
            informaci&oacute;n demogr&aacute;fica.
          </P>
          <Subtitle>Aceptaci&oacute;n y consentimiento</Subtitle>
          <P>
            Como Usuario del sitio web declaras haber sido informado de las
            condiciones sobre protecci&oacute;n de datos de car&aacute;cter
            personal, aceptas y consientes el tratamiento de los mismos por
            parte de el Titular en la forma y para las finalidades indicadas en
            esta Pol&iacute;tica de Privacidad.
          </P>
          <Subtitle>Cambios en la pol&iacute;tica de privacidad</Subtitle>
          <P>
            El Titular se reserva el derecho a modificar la presente
            Pol&iacute;tica de Privacidad para adaptarla a novedades
            legislativas o jurisprudenciales, as&iacute; como a pr&aacute;cticas
            de la industria.
          </P>
          <P>
            Estas pol&iacute;ticas estar&aacute;n vigentes hasta que sean
            modificadas por otras debidamente publicadas.
          </P>
          <P>
            En cumplimiento con lo dispuesto en el art&iacute;culo 22.2 de la
            Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
            Informaci&oacute;n y de Comercio Electr&oacute;nico, esta
            p&aacute;gina web le informa, en esta secci&oacute;n, sobre la
            pol&iacute;tica de recogida y tratamiento de cookies.
          </P>
        </Copy>
      </Section>
      <Section id="politica-de-cookies" style={props}>
        <Copy>
          <H2>Pol&iacute;tica de cookies</H2>
          <Subtitle>&iquest;Qu&eacute; son?</Subtitle>
          <P>
            Una cookie es un fichero que se descarga en su ordenador al acceder
            a determinadas p&aacute;ginas web. Las cookies permiten a una
            p&aacute;gina web, entre otras cosas, almacenar y recuperar
            informaci&oacute;n sobre los h&aacute;bitos de navegaci&oacute;n de
            un usuario o de su equipo y, dependiendo de la informaci&oacute;n
            que contengan y de la forma en que utilice su equipo, pueden
            utilizarse para reconocer al usuario.
          </P>
          <Subtitle>&iquest;Cu&aacute;les las utilizamos?</Subtitle>
          <P>
            Cookies de an&aacute;lisis - Son aqu&eacute;llas que bien tratadas
            por nosotros o por terceros, nos permiten cuantificar el
            n&uacute;mero de usuarios y as&iacute; realizar la medici&oacute;n y
            an&aacute;lisis estad&iacute;stico de la utilizaci&oacute;n que
            hacen los usuarios del servicio ofertado. Para ello se analiza su
            navegaci&oacute;n en nuestra p&aacute;gina web con el fin de mejorar
            la oferta de productos o servicios que le ofrecemos.
          </P>
          <Subtitle>C&oacute;mo desactivar las Cookies</Subtitle>
          <P>
            Puede usted permitir, bloquear o eliminar las cookies instaladas en
            su equipo mediante la configuraci&oacute;n de las opciones del
            navegador instalado en su ordenador. A continuaci&oacute;n puede
            acceder a la configuraci&oacute;n de los navegadores webs m&aacute;s
            frecuentes para aceptar, instalar o desactivar las cookies:
          </P>

          <List>
            <li>
              <A
                href="https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-"
                target="_blank"
                rel="nofollow"
              >
                Firefox
              </A>
            </li>
            <li>
              <A
                href="https://support.apple.com/es-es/HT201265"
                target="_blank"
                rel="nofollow"
              >
                Safari
              </A>
            </li>
            <li>
              <A
                href="https://support.google.com/chrome/answer/95647?hl=es"
                target="_blank"
                rel="nofollow"
              >
                Google Chrome
              </A>
            </li>
          </List>
        </Copy>
      </Section>
    </>
  )
}

export default TermsAndConditions

const Copy = styled.div({
  flex: `1 1 70%`,
  maxWidth: 650,
  [mqTabletHandheld]: {
    flex: `1 0 100%`,
  },
  "> a, p, p > a,> ul > li > a": {
    fontFamily: `Graphik`,
    fontSize: 16,
    lineHeight: `20px`,
    marginBottom: 30,

    [mqTablet]: {
      fontSize: 20,
      lineHeight: `29px`,
    },
    [mqHandheld]: {
      fontSize: 15,
      lineHeight: `23px`,
    },
  },
  "> p > a, > ul > li > a, > ul > li > p > a": {
    fontWeight: `inherit`,
  },
  "> a": {
    lineHeight: `22px`,
  },
})

const Subtitle = styled(P)({
  fontWeight: `bolder`,
})

const A = styled(P)({
  textDecoration: `underline`,
  fontWeight: `bolder`,
}).withComponent("a")

const Nav = styled.nav({})

const List = styled.ul({
  paddingLeft: 20,
  "> li > p": {
    margin: 0,
  },
})
